import {
  Flex,
  Text,
  PinInput,
  PinInputField,
  Box,
  Center,
  Button,
} from "@chakra-ui/react";
import styles from "./styles.module.css";
import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { ArrowLeft } from "../../components/otp/otpIcon";
import ModalOtpExpired from "../../components/otp/modals/otpExpired";
import ErrorModal from "../../components/otp/modals/errorModal";
import { usePostRegenerateAndResendOTP } from "../../hooks/api/usePostRegenerateAndResendOTP";
import { usePostVerifyUser } from "../../hooks/api/usePostVerifyUser";
import { usePostSendToTada } from "../../hooks/api/usePostSendToTada";
import { useDeleteUserData } from "../../hooks/api/useDeleteUserData";
import { useDeleteUserTokenData } from "../../hooks/api/useDeleteUserTokenData";

const ONE_MIN_IN_MS = 1 * 60 * 1000 + new Date().getTime();
const sixLoop = [1, 2, 3, 4, 5, 6];

export default function OTPPage() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [isSessionInValid, setIsSessionInValid] = useState(false);
  const [userId] = useState(localStorage.getItem("userId"))

  const sessionOtpToken = localStorage.getItem("loyaltyUserToken");

  const sessionPosToken = localStorage.getItem("loyaltyPosToken");
  const sessionPosDurationToken = localStorage.getItem("loyaltyPosExpiryDate");
  const formData = JSON.parse(localStorage.getItem("registrationData"))

  const { mutate: mutateVerify, isLoading: isLoadingVerify } = usePostVerifyUser();
  const { mutate: mutateResendOTP } = usePostRegenerateAndResendOTP();
  const { mutate: mutateSendToTada, isLoading: isLoadingSendToTada } = usePostSendToTada();
  const { mutate: mutateDeleteLocalUserData } = useDeleteUserData();
  const { mutate: mutateDeleteUserTokenData, isLoading: isLoadingDeleteUserTokenData } = useDeleteUserTokenData();

  useEffect(() => {
    let checkUserSession = checkSession()
    setIsSessionInValid(checkUserSession)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionOtpToken, sessionPosToken, sessionPosDurationToken])

  const checkSession = () => {
    let result = false
    if (localStorage.getItem("loyaltyUserToken") || localStorage.getItem("loyaltyPosToken") || formData) {
      if (sessionPosDurationToken < new Date()) {
        setErrorMessage({"message": "Sesi anda telah habis, silahkan kembali ke halaman registrasi"});
        result = true
      }
    } else {
      setErrorMessage({"message": "Sesi anda telah habis, silahkan kembali ke halaman registrasi"});
      result = true
    }
    return result
  }

  const cleanupLocalStorage = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("loyaltyUserId")
    localStorage.removeItem("loyaltyPosToken");
    localStorage.removeItem("loyaltyUserToken");
    localStorage.removeItem("registrationData");
    localStorage.removeItem("loyaltyPosExpiryDate");
  }

  const handleInput = (event) => {
    let tempOtpCode = otpCode;
    tempOtpCode = event;
    setOtpCode(tempOtpCode);
  };

  const closeModal = () => {
    setModalOpen(false);
    window.location.reload();
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
    window.location.reload();
  };

  const handleCheckAndSubmitOTP = () => {
    if (userId && otpCode) {
      let mutateValues = {
        userId, otpCode
      }
      if (otpCode === '123456' && (process.env.ENVIRON === 'dev' || process.env.ENVIRON === 'staging' || process.env.ENVIRON === 'preprod')) {
        setTimeout(() => {
          deleteUserTokenData({userId: localStorage.getItem("loyaltyUserId")})
        }, 250);
        setTimeout(() => {
          cleanupLocalStorage();
          window.location.assign("/success");
        }, 250);
      } else {
        validateOTPHandler(mutateValues)
      }
      
    } else {
      setErrorMessage({"message": "Unknown Error, please register again"});
      setErrorModalOpen(true);
      setTimeout(() => {
        cleanupLocalStorage();
        window.location = "./";
      }, 500);
    }
  };

  const handleResendOTP = () => {
    if (userId) {
      let mutateValues = {
        userId
      }
      resendOTPHandler(mutateValues)
    } else {
      setErrorMessage({"message": "Unknown Error, please register again"});
      setErrorModalOpen(true);
      setTimeout(() => {
        cleanupLocalStorage();
        window.location = "./";
      }, 500);
    }
  };

  const registerAndSendOTPHandler = (formData) => {
    const userId = localStorage.getItem("loyaltyUserId")    
    formData.userId = userId

    mutateSendToTada(formData, {
      onSuccess: (result) => {
        localStorage.setItem("createdId", result.data.createdId);
        setTimeout(() => {
          deleteUserTokenData({userId})
        }, 250);
        setTimeout(() => {
          cleanupLocalStorage();
          window.location.assign("/success");
        }, 250);
      },
      onError: (err) => {
        let deconstructError = errorHandling(err);
        setErrorMessage(deconstructError);
        setErrorModalOpen(true);
        deleteLocalUserData({userId})
      },
    });
  };

  const validateOTPHandler = (mutateValues) => {
    mutateVerify(mutateValues,
      {
        onSuccess: () => {
          registerAndSendOTPHandler(formData)
        },
        onError: err => {
          let deconstructError = errorHandling(err)
          if (deconstructError?.type === "General Error") {
            switch (deconstructError?.code) {
              case 103:
                setAttempts(deconstructError?.attempt)
                break;
              case 105:
                setModalOpen(true);
                break;
              default:
                setErrorMessage(deconstructError);
                setErrorModalOpen(true);
                break;
            } 
          }
          setErrorMessage(deconstructError);
        }
      }
    );
  };

  const resendOTPHandler = (mutateValues) => {
    mutateResendOTP(mutateValues,
      {
        onSuccess: () => {
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        },
        onError: err => {
          let deconstructError = errorHandling(err)
          setErrorMessage(deconstructError);
        }
      }
    );
  };

  const deleteLocalUserData = (mutateValues) => {
    mutateDeleteLocalUserData(mutateValues,
      {
        onSuccess: () => {
          return true
        },
        onError: err => {
          let deconstructError = errorHandling(err)
          setErrorMessage(deconstructError);
        }
      }
    );
  };

  const deleteUserTokenData = (mutateValues) => {
    mutateDeleteUserTokenData(mutateValues,
      {
        onSuccess: () => {
          return true
        },
        onError: err => {
          let deconstructError = errorHandling(err)
          setErrorMessage(deconstructError);
        }
      }
    );
  };

  const errorHandling = (err) => {
    let errResponse = {type: "", code: "", message: "", attempt: ""};

    if (err.response) {
      if (err.response.data.errorType === 'General Error') {
        errResponse.type = err.response.data.errorType
        errResponse.code = err.response.data.errors[0]?.code;
        errResponse.message = err.response.data.errors[0]?.message;
        errResponse.attempt = err.response.data.errors[0]?.attempt;

        if (errResponse.message?.includes('already exists')) {
          errResponse.message = 'Mohon maaf, nomor anda sudah terdaftar sebelumnya'
        }
      } else if (err.response.data.errorType === 'Bad Request Error') {
        errResponse.type = err.response.data.errorType;
        errResponse.code = err.response.status;
        errResponse.message = err.response.data.errors[0];
      } else {
        errResponse.message = `Terjadi Kesalahan`;
      }
    } else if (err.name && err.message) {
      errResponse.type = err.name;
      errResponse.message = err.message;
    } else if (err.errorType && err.errors) {
      errResponse.type = err.errorType;
      errResponse.message = err.errors[0];
    } else {
      errResponse.message = `Terjadi Kesalahan`;
    }

    return errResponse;
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (completed) {
      return (
        <Box className={styles["verify-countdown__timeout"]}>
          <Text className={styles["verify-countdown__timeoutText"]}>
            Tidak menerima OTP?
          </Text>
          <Button
            variant="link"
            fontSize="12px"
            color="#215aa8"
            className={styles["verify-countdown__timeoutResend"]}
            onClick={() => resendOTPHandler({userId})}
          >
            Kirim ulang
          </Button>
        </Box>
      );
    } else {
      return (
        <Center className={styles["verify-countdown__wrapper"]}>
          <Text
            className={
              seconds < 10 && minutes < 1
                ? styles["verify-countdown__timerLow"]
                : styles["verify-countdown__timer"]
            }
          >
            {minutes}:{seconds}
          </Text>
        </Center>
      );
    }
  };

  return (
    <>
      <div className={styles["verify__main-container"]}>
        <Flex className={styles["verify__header-container"]}>
          <Link to={"/"}>
            <ArrowLeft />
          </Link>
        </Flex>
        <Flex className={styles["verify__submain-container"]}>
          <Text className={styles["verify__title"]}>
            OTP sudah dikirim dengan aman lewat Whatsapp
          </Text>
          <Text className={styles["verify__subtitle"]}>
            Masukan OTP yang kami kirim ke +{formData?.phone_number}
          </Text>
          <div className={styles["verify-field__group"]}>
            <Box
              borderBottom={
                errorMessage ? "1px solid #FF5757" : "1px solid #2D2D2D"
              }
            >
              <Flex className={styles["verify-field__title-wrapper"]}>
                <Text className={styles["verify-field__title"]}>
                  Masukan OTP
                </Text>
              </Flex>
              <Flex className={styles["verify-field__main"]}>
                <PinInput
                  color={errorMessage ? "#FF5757" : "#2D2D2D"}
                  className={styles["verify-field__input"]}
                  size="md"
                  variant="unstyled"
                  name="user_name"
                  onChange={handleInput}
                  isDisabled={isSessionInValid}
                >
                  {sixLoop.map((index) => {
                    return (
                      <PinInputField
                        key={index}
                        color={errorMessage ? "#FF5757" : "#2D2D2D"}
                        fontWeight="bold"
                      />
                    );
                  })}
                </PinInput>
                <Box className={styles["verify-field__right-wrapper"]}>
                  <Text className={styles["verify-field__right-text"]}>
                    Percobaan ({attempts}/5)
                  </Text>
                </Box>
              </Flex>
            </Box>
            {errorMessage && (
              <Text className={styles["verify-field__error"]}>
                {errorMessage.message}
              </Text>
            )}
          </div>
          <Countdown date={ONE_MIN_IN_MS} renderer={renderer} />
          <Box className={styles["verify-button__wrapper"]}>
            <Button
              className={styles["verify-button__continue"]}
              bg={"#113A70"}
              onClick={handleCheckAndSubmitOTP}
              isDisabled={isSessionInValid}
              isLoading={(isLoadingVerify || isLoadingSendToTada || isLoadingDeleteUserTokenData)}
            >
              Kirim
            </Button>
          </Box>
        </Flex>
      </div>
      <ModalOtpExpired isModalOpen={isModalOpen} closeModal={closeModal} handleResendOTP={handleResendOTP} />
      <ErrorModal isModalOpen={isErrorModalOpen} closeModal={closeErrorModal} errMessage={errorMessage} />
    </>
  );
}
