import { useMutation } from '@tanstack/react-query';
import Axios from '../../utils/interceptorOTPAuth';

export const usePostVerifyUser = () => {
  return useMutation(async (body) => {
    const res = await Axios({
      url: `auth/verify-user/${body?.userId}`,
      data: {
        "otpCode": body?.otpCode,
        "otpMethod": "whatsapp"
      },
    });

    return res.data;
  });
};