import { useMutation } from '@tanstack/react-query';
import authAxios from '../../utils/interceptorOTPAuth';

export const usePostRegenerateAndResendOTP = () => {
  return useMutation(async (body) => {
    const res = await authAxios({
      url: `auth/send-otp/${body?.userId}`,
      data: {
        "otpMethod": "whatsapp"
      },
    });

    return res.data;
  });
};
