import React from "react";
import {
  Box,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  Stack,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";

import { Search2Icon } from "@chakra-ui/icons";
import styles from "./styles.module.css";

export default function StoreDrawer({
  isOpen,
  onClose,
  options,
  searchProduct,
  handleChooseStore,
  selectedStoreName,
}) {
  return (
    <>
      <Drawer size="sm" isOpen={isOpen} onClose={onClose} placement={"bottom"}>
        <DrawerOverlay />
        <DrawerContent className={styles["store-drawer__box"]}>
          <Box>
            <DrawerCloseButton />
            <DrawerHeader>
              <Text className={styles["store-drawer__heading"]}>
                Pilih Toko
              </Text>
            </DrawerHeader>
          </Box>
          <DrawerBody className={styles["store-drawer__body"]}>
            <InputGroup className={styles["store-drawer__search"]}>
              <InputLeftElement>
                <Search2Icon />
              </InputLeftElement>
              <Input
                type="text"
                onChange={searchProduct}
                placeholder="Cari nama toko"
                variant="flushed"
                name="store_name"
              />
            </InputGroup>
            <RadioGroup
              name="store"
              id="store"
              onChange={handleChooseStore}
              defaultValue={selectedStoreName}
            >
              {options?.map((value) => {
                return (
                  <Stack className={styles["store-radio-wrapper"]} textAlign="left">
                    <Radio value={value.name} width="100%">
                      {value.name}
                    </Radio>
                  </Stack>
                );
              })}
            </RadioGroup>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
