import { useMutation } from '@tanstack/react-query';
import Axios from '../../utils/interceptorPOS';

export const usePostAuthenticate = () => {
  return useMutation(async (body) => {
    const res = await Axios({
      method: 'POST',
      url: 'user',
      data: body
    });
    return res;
  });
};
