import { useQuery } from '@tanstack/react-query';
import Axios from '../../utils/interceptorPOS';

export const useGetStoreData = (isEnabled) => {
  return useQuery(
    ['store', isEnabled],
    async () => {
      const res = await Axios({
        method: 'GET',
        url: 'store',
      });

      return res.data.data.stores;
    },
    {
      staleTime: 5 * 60 * 1000, // 5 min
      cacheTime: 10 * 60 * 1000, // 10 min
      enabled: isEnabled
    }
  );
};

