import React from "react";
import { Box, Image } from "@chakra-ui/react";
import HeaderImage from "../../../assets/image/membership-form-banner.png";

function MainHeader() {
  return (
    <Box maxHeight="203px" width="100%">
      <Image maxHeight="203px" width="100%" src={HeaderImage} objectFit="fit" loading="lazy" />
    </Box>
  );
}

export default MainHeader;
