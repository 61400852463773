import { useMutation } from '@tanstack/react-query';
import authAxios from '../../utils/interceptorPOSAuth';

export const useDeleteUserData = () => {
  return useMutation(async (values) => {
    const res = await authAxios({
      method: 'DELETE',
      url: `userAuth/${values?.userId}`,
    });

    return res;
  });
};
