import { useMutation } from '@tanstack/react-query';
import authAxios from '../../utils/interceptorPOSAuth';

export const usePostVerifyCaptcha = () => {
  return useMutation(async (USER_TOKEN) => {
    return await authAxios({
      method: 'POST',
      url: 'verify-recaptcha-token',
      data: USER_TOKEN
    });
  });
};

