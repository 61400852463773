import React from "react";

function FallbackErrorPage({ error, resetErrorBoundary }) {
  const onHandleBackToHome = () => {
    window.location.pathname = "/";
  };
  return (
    <div
      style={{
        width: "100%",
        height: "95vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error?.message}</pre>
        <button onClick={resetErrorBoundary}>Try Again</button>
        <button style={{ marginLeft: "1em" }} onClick={onHandleBackToHome}>
          Back To Home
        </button>
      </div>
    </div>
  );
}

export default FallbackErrorPage;
