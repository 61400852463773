import Axios from "axios";
import React, { useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";

function RouteErrorFetch(props) {
  const { showBoundary } = useErrorBoundary();

  const fetchData = async () => {
    try {
      const { data } = await Axios({
        url: "https://jsonplaceholder.typicode.com/tod",
        method: "get",
      });
      console.log("response data", data);
    } catch (err) {
      showBoundary(err);
    }
  };
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Fetching data</div>;
}

export default RouteErrorFetch;
