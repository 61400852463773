import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import loggingError from "./services/loggingError";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import FallbackErrorPage from "./FallbackErrorPage";
import RouteErrorPage from "./RouteErrorPage";
import RouteErrorFetch from "./RouteErrorFetch";
import FallbackNotFound from "./FallbackNotFound";
import About from "./pages/about";
import FormRegistration from "./pages/form";
import OTPPage from "./pages/otp";
import SuccessPage from "./pages/success";

const queryClient = new QueryClient()

const theme = extendTheme({
  components: {
    Tooltip: {
      variants: {
        "white": {
          bg: "white",
          color: "black",
          padding: "16px",
          borderRadius: "4px",
        }
      },
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ErrorBoundary FallbackComponent={FallbackErrorPage} onError={loggingError}>
        <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <Routes>
              <Route path="/" element={<FormRegistration />} />
              <Route path="/home" element={<About />} />
              <Route path="/verification" element={<OTPPage />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/error-fetching" element={<RouteErrorFetch />} />
              <Route path="/error-page" element={<RouteErrorPage />} />
              <Route path="*" element={<FallbackNotFound />} />
            </Routes>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
