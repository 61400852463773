import React from "react";
import {
  Box,
  Text,
  Drawer,
  DrawerFooter,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
} from "@chakra-ui/react";

import styles from "./styles.module.css";

export default function HomeInfoDrawer({ isOpen, onClose, elementData }) {
  return (
    <>
      <Drawer size="sm" isOpen={isOpen} onClose={onClose} placement={"bottom"}>
        <DrawerOverlay />
        <DrawerContent className={styles["drawer__box"]}>
          <Box>
            <DrawerCloseButton />
            <DrawerHeader>
              <Text className={styles["drawer__heading"]}>{elementData.title}</Text>
            </DrawerHeader>
          </Box>
          <DrawerBody className={styles["drawer__body"]}>
            <Box>
              <Text style={{ textAlign: "left" }}>
                {elementData.info !== "info" ? elementData.info : "Hang tight, coming soon!"}
              </Text>
            </Box>
          </DrawerBody>
          <Box>
            <DrawerFooter />
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
}
