import axios from 'axios';

const Axios = axios.create({
  baseURL: process.env.REACT_APP_LOYALTY_POS_ENDPOINT,
});

const requestHandler = request => {
  return request;
};
const responseHandler = response => {
  return response;
};
const errorRequestHandler = e => {
  return Promise.reject(e);
};
const errorResponseHandler = e => {
  if (e.response && e.response.status === 401) {
    window.location = './';
    localStorage.removeItem('auth:token');
    localStorage.removeItem('auth:user');
  }
  return Promise.reject(e);
};

Axios.interceptors.request.use(
  request => requestHandler(request),
  error => errorRequestHandler(error)
);

Axios.interceptors.response.use(
  response => responseHandler(response),
  error => errorResponseHandler(error)
);
export default Axios;
