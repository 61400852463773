import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Box,
  Text,
  Center,
  ModalFooter,
} from "@chakra-ui/react";

import styles from "./styles.module.css";
import Countdown from "react-countdown";
import { RedCross } from "../../otpIcon";

const THREE_MIN_IN_MS = 0.2 * 60 * 1000 + new Date().getTime();

export default function ModalOtpExpired({
  isModalOpen,
  closeModal,
  handleResendOTP,
}) {
  const renderer = ({ minutes, seconds, completed }) => {
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (completed) {
      return (
        <Button
          width="75%"
          marginBottom="12px"
          color="#FFFFFF"
          bg="#113A70"
          onClick={() => {
            handleResendOTP();
            closeModal();
          }}
          className="medium-font-style"
        >
          Kirim Ulang OTP
        </Button>
      );
    } else {
      return (
        <Center className={styles["modalExpired-countdown__wrapper"]}>
          <Text
            className={
              seconds < 10 && minutes < 1
                ? styles["modalExpired-countdown__timerLow"]
                : styles["modalExpired-countdown__timer"]
            }
          >
            {minutes}:{seconds}
          </Text>
        </Center>
      );
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isModalOpen}
      size={"xs"}
      onClose={() => closeModal()}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody align="center" justifyContent="flex-center" pr={6} pl={6}>
          <Box width="100%" padding="33px 33px 0">
            <RedCross />
          </Box>
          <Box width="100%">
            <Text className={styles["modalExpired-title"]}>
              Percobaan Habis
            </Text>
            <Text className={styles["modalExpired-subtitle"]}>
              Percobaan input OTP habis, Anda sudah memasukan kode OTP 5 kali.
              Tunggu beberapa saat untuk mencoba lagi
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center">
          <Countdown date={THREE_MIN_IN_MS} renderer={renderer} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
