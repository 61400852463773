import { useMutation } from '@tanstack/react-query';
import Axios from '../../utils/interceptorOTP';

export const usePostRegisterUserAndSendOTP = () => {
  return useMutation(async (body) => {
    const res = await Axios({
      url: "register-user",
      data: {
        "phone": body.phone_number,
        "registerSource": body.store && "store",
      }
    });

    return res.data;
  });
};