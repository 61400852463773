import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Box,
  Text,
  ModalFooter,
} from "@chakra-ui/react";

import styles from "./styles.module.css";
import { RedCross } from "../../otpIcon";

export default function ModalOTPError({
  isModalOpen,
  closeModal,
  errMessage,
}) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isModalOpen}
      size={"xs"}
      onClose={() => closeModal()}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody align="center" justifyContent="flex-center" pr={6} pl={6}>
          <Box width="100%" padding="33px 33px 0">
            <RedCross />
          </Box>
          <Box width="100%">
            <Text className={styles["modalExpired-title"]}>
              Terjadi Kesalahan
            </Text>
            <Text className={styles["modalExpired-subtitle"]}>
              {errMessage.message}
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center">
          <Button
            width="75%"
            marginBottom="12px"
            color="#FFFFFF"
            bg="#113A70"
            onClick={() => {
              closeModal();
              localStorage.removeItem("userId");
              localStorage.removeItem("loyaltyPosToken");
              localStorage.removeItem("loyaltyPosExpiryDate");
              window.location.replace("./");
            }}
            className="medium-font-style"
          >
            Kembali
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
