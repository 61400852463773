const Dummy = () => <></>;

export default Dummy;

export const ArrowLeft = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7 6.72501C10.9 6.92501 10.996 7.16668 10.988 7.45001C10.98 7.73335 10.8757 7.97501 10.675 8.17501L7.85 11L19 11C19.2833 11 19.521 11.096 19.713 11.288C19.905 11.48 20.0007 11.7173 20 12C20 12.2833 19.904 12.521 19.712 12.713C19.52 12.905 19.2827 13.0007 19 13L7.85 13L10.7 15.85C10.9 16.05 11 16.2877 11 16.563C11 16.8383 10.9 17.0757 10.7 17.275C10.5 17.475 10.2623 17.575 9.987 17.575C9.71166 17.575 9.47433 17.475 9.275 17.275L4.7 12.7C4.6 12.6 4.529 12.4917 4.487 12.375C4.445 12.2583 4.42433 12.1333 4.42499 12C4.42499 11.8667 4.446 11.7417 4.488 11.625C4.53 11.5083 4.60066 11.4 4.7 11.3L9.3 6.70001C9.48333 6.51668 9.71233 6.42501 9.987 6.42501C10.2617 6.42501 10.4993 6.52501 10.7 6.72501Z"
      fill="#2D2D2D"
    />
  </svg>
);

export const RedCross = () => (
  <svg
    width="61"
    height="61"
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30.5" cy="30.5" r="30.5" fill="#FE7070" />
    <path
      d="M39.0464 22.9668L39.0467 22.9672C39.3581 23.2786 39.3581 23.8153 39.0467 24.1267L32.5267 30.6467L32.1732 31.0003L32.5267 31.3539L39.0467 37.8738C39.1238 37.9509 39.1848 38.0423 39.2265 38.1429C39.2682 38.2435 39.2897 38.3514 39.2897 38.4603C39.2897 38.5692 39.2682 38.6771 39.2265 38.7777C39.1849 38.8783 39.1238 38.9697 39.0467 39.0467C38.9697 39.1238 38.8783 39.1849 38.7777 39.2265C38.6771 39.2682 38.5692 39.2897 38.4603 39.2897C38.3514 39.2897 38.2435 39.2682 38.1429 39.2265C38.0423 39.1848 37.9509 39.1238 37.8739 39.0467L31.3539 32.5267L31.0003 32.1732L30.6467 32.5267L24.1267 39.0467C24.0497 39.1238 23.9583 39.1848 23.8577 39.2265C23.7571 39.2682 23.6492 39.2897 23.5403 39.2897C23.4314 39.2897 23.3235 39.2682 23.2229 39.2265C23.1223 39.1848 23.0309 39.1238 22.9539 39.0467C22.8768 38.9697 22.8157 38.8783 22.7741 38.7777C22.7324 38.6771 22.7109 38.5692 22.7109 38.4603C22.7109 38.3514 22.7324 38.2435 22.7741 38.1429C22.8157 38.0423 22.8768 37.9509 22.9539 37.8739L29.4739 31.3539L29.8274 31.0003L29.4739 30.6467L22.9539 24.1267C22.8768 24.0497 22.8157 23.9583 22.7741 23.8577C22.7324 23.7571 22.7109 23.6492 22.7109 23.5403C22.7109 23.4314 22.7324 23.3235 22.7741 23.2229C22.8157 23.1223 22.8768 23.0309 22.9539 22.9539C23.0309 22.8768 23.1223 22.8157 23.2229 22.7741C23.3235 22.7324 23.4314 22.7109 23.5403 22.7109C23.6492 22.7109 23.7571 22.7324 23.8577 22.7741C23.9583 22.8157 24.0497 22.8768 24.1267 22.9539L30.6467 29.4739L30.9999 29.827L31.3535 29.4742L37.8735 22.9675L37.8742 22.9668C37.9511 22.8898 38.0425 22.8286 38.143 22.7869C38.2436 22.7452 38.3514 22.7237 38.4603 22.7237C38.5692 22.7237 38.677 22.7452 38.7775 22.7869C38.8781 22.8286 38.9695 22.8898 39.0464 22.9668Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

export const SuccessIcon = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="60" cy="60" r="60" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.833 60L38.1247 52.7083L52.708 67.2917L81.8747 38.125L89.1663 45.4167L52.708 81.875L30.833 60Z"
      fill="#113A70"
      stroke="#113A70"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
