import { useMutation } from '@tanstack/react-query';
import authAxios from '../../utils/interceptorPOSAuth';

export const usePostSendToTada = () => {
  return useMutation(async (body) => {
    let registrationData = {
      'name': body.user_name,
      'dateofbirth': body.dateofbirth,
      'phone': `+${body.phone_number}`,
      'registerSource': body.store,
    }

    if (body.email_address) registrationData.email = body.email_address

    const res = await authAxios({
      method: 'POST',
      url: `userRegister/v2/${body.userId}`,
      data: registrationData
    });

    return res;
  });
};
