import React from "react";

function FallbackNotFound(props) {
  const onHandleBackToHome = () => {
    window.location.pathname = "/";
  };
  return (
    <div
      style={{
        width: "100%",
        height: "95vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <div role="alert">
        <p>Page Not Found</p>
        <button onClick={onHandleBackToHome}>Back To Home</button>
      </div>
    </div>
  );
}

export default FallbackNotFound;
