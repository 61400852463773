import React from "react";
import { Link } from "react-router-dom";
import { Box, Text, Button } from "@chakra-ui/react";
import { SuccessIcon } from "../../components/otp/otpIcon";
import styles from "./styles.module.css";

function SuccessPage() {
  return (
    <Box className={styles["success__main-container"]}>
      <Box className={styles["success__submain-container"]}>
        <SuccessIcon />
        <Text className={styles["success__register-title"]}>
          Pendaftaran Berhasil
        </Text>
        <Text className={styles["success__register-subtitle"]}>
          Pendaftaran akun membership berhasil. Anda dapat menutup halaman ini
          atau kembali ke halaman awal
        </Text>
        <Link to={"/home"}>
          <Button
            className={styles["success__button-text"]}
            background="#FFFFFF"
            borderRadius="4px"
            width="257px"
          >
            Kembali
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

export default SuccessPage;
