import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Image,
  useDisclosure,
  Center,
  Heading,
  Grid,
} from "@chakra-ui/react";

import styles from "./about.module.css";
import HomeInfoDrawer from "../../components/form/drawer/home-drawer";
import { useSearchParams } from "react-router-dom";
// import { useGetActivitiesData } from "../../hooks/api/useGetActivitiesData";

import ColorBall from "../../assets/image/color-ball.png";
import ShopMoreEarnMore from "../../assets/image/shop-more-earn-more.png";
import MembershipCard from "../../assets/image/membership-card.png";
import MembershipLevel from "../../assets/image/membership-level-text.png";
import HowItWorks from "../../assets/image/how-it-works.png";

import Wardah from "../../assets/image/brands/wardah.png";
import Emina from "../../assets/image/brands/emina.png";
import Makeover from "../../assets/image/brands/makeover.png";
import Biodef from "../../assets/image/brands/biodef.png";
import Kahf from "../../assets/image/brands/kahf.png";
import Labore from "../../assets/image/brands/labore.png";
import Tavi from "../../assets/image/brands/tavi.png";
import Wonderly from "../../assets/image/brands/wonderly.png";
import Putri from "../../assets/image/brands/putri.png";
import Crystallure from "../../assets/image/brands/crystallure.png";
import Instaperfect from "../../assets/image/brands/ip.png";
import EarthLoveLife from "../../assets/image/brands/ell.png";

import {
  EclipseShadow,
  CrystalTierIcon,
  DiamondTierIcon,
  EmeraldTierIcon,
  JoinForFreeIcon,
  EarnPointsIcon,
  RedeemPointsIcon,
  UpgradeIcon,
  InfoIcon,
  VoucherIcon,
  SurpriseGiftIcon,
  BirthdayDiscountIcon,
  FreebiesIcon,
  BeautyClassIcon,
} from "../../components/about/icons";

const activitiesData = [
  {
    title: "Complete beauty profile",
    isInfoActive: true,
    info: "Beauty profile can be found on your account in official website of Wardah / Emina / Make Over. Points will be earned only once, after profile completion in any brand.",
    points: 10000,
  },
  {
    title: "Empties Program",
    isInfoActive: false,
    info: "Bring your empty or old beauty products' bottle to any of Wardah Beauty House, Emina Playground, official Make Over stores, and Looklab.",
    points: 1000,
  },
  {
    title: "Use Virtual Try On feature",
    isInfoActive: true,
    info: "Virtual try on can be found in official website of Wardah (Beauty Match, Shade Match), Emina (Virtual Beauty Room), Make Over (Virtual Try On). Points will be earned only once, after trying any virtual try on in any brand.",
    points: 1000,
  },
  {
    title: "Use Wardah Skin Analyzer feature",
    isInfoActive: true,
    info: "Sign in to Wardah official website and go to Advanced Skinlab. Follow the instructions to get the analysis result of your skin.",
    points: 1000,
  },
  {
    title: "Complete LABORE skin routine finder quiz",
    isInfoActive: false,
    info: "Sign in to Labore official website and go to Skin Routine Finder. Follow the instructions to get the best skincare routine according to your skin needs analysis.",
    points: 1000,
  },
  {
    title: "Complete TAVI skin quiz",
    isInfoActive: false,
    info: "Sign in to Tavi official website and go to Skin Quiz. Follow the instructions to get tips & tricks for your skin concern.",
    points: 1000,
  },
  {
    title: "Join Wonderly community",
    isInfoActive: false,
    info: "Go to Wonderly official website and join Glow Queens 👑",
    points: 1000,
  },
  {
    title: "Product review",
    isInfoActive: true,
    info: "Review our products at any brands' official website and get 200 points on any of your verified review!",
    points: 200,
  }
];

function About() {
  const [params] = useSearchParams();
  const [activities] = useState(activitiesData);
  const [store] = useState(params.get("store"));
  const [elementData, setElementData] = useState({});
  const {
    isOpen: isOpenInfoData,
    onOpen: onOpenInfoData,
    onClose: onCloseInfoData,
  } = useDisclosure();

  // const { data: returnedActivities, isError } = useGetActivitiesData(true);

  useEffect(() => {
    if (store) {
      localStorage.setItem("store", store);
    }
    // const activityList = !isError ? activitiesData : returnedActivities
    // setActivities(activitiesData)
  }, [store]);

  return (
    <div className={styles["main-container"]}>
      <Center
        position="absolute"
        zIndex="1"
        marginRight="14em"
        marginLeft="-2em"
        marginTop="-2em"
      >
        <Image src={ColorBall} loading="lazy" />
      </Center>
      <Box
        id="pageHeader"
        className={styles["header-container"]}
        zIndex="2"
        position="relative"
      >
        <Heading className={styles["header-text-title"]} fontFamily={"Poppins"}>
          Paragon
          <br />
          Membership
        </Heading>
        <Text className={styles["header-text-subtitle"]}>
          Exclusive reward and privileges for every purchase just for you. Shop
          more, earn more, and level up faster!
        </Text>
        <Button
          bgColor="#4C5A7A"
          color="#FFFFFF"
          size="md"
          mt="0.5em"
          onClick={() => {
            window.location.assign("./");
          }}
        >
          Join Now
        </Button>
      </Box>
      <Box id="membershipLevel">
        <Box mb="2em">
          <Image
            maxWidth="220px"
            src={MembershipLevel}
            objectFit="fit"
            loading="lazy"
          />
        </Box>
        <Flex>
          <Box id="imageEclipseShadow" width="50%" margin="auto">
            <Center>
              <Image src={MembershipCard} objectFit="fit" maxWidth="200px" loading="lazy" />
            </Center>
            <Center>
              <EclipseShadow />
            </Center>
          </Box>
          <Box width="2.5%"></Box>
          <Box width="47.5%" margin="0.75em 0">
            <Center className={styles["membership-level-container"]}>
              <Box width="15%">
                <CrystalTierIcon />
              </Box>
              <Box width="50%">
                <Text className={styles["membership-level-title"]}>Crystal</Text>
                <Text className={styles["membership-level-subtitle"]}>
                  Your first level
                </Text>
              </Box>
            </Center>
            <Center className={styles["membership-level-container"]}>
              <Box width="20%">
                <DiamondTierIcon />
              </Box>
              <Box width="55%">
                <Text className={styles["membership-level-title"]}>Diamond</Text>
                <Text className={styles["membership-level-subtitle"]}>
                  Collect 20k points
                </Text>
              </Box>
            </Center>
            <Center className={styles["membership-level-container"]}>
              <Box width="15%">
                <EmeraldTierIcon />
              </Box>
              <Box width="55%">
                <Text className={styles["membership-level-title"]}>Emerald</Text>
                <Text className={styles["membership-level-subtitle"]}>
                  Collect 30k points
                </Text>
              </Box>
            </Center>
          </Box>
        </Flex>
      </Box>
      <Box id="shopToEarnMore" className={styles["shop-earn-more-container"]}>
        <Box margin={"0 1.5em"}>
          <Text
            textAlign="center"
            className={styles["membership-rewards-text"]}
          >
            Get wonderful rewards for every purchase and enjoy lots of exclusive
            promotions!
          </Text>
        </Box>
        <Box>
          <Center className={styles["shop-earn-more-row-top"]}>
            <Center
              position="absolute"
              zIndex="1"
              marginRight="8.5em"
              marginLeft="1em"
              marginTop="1em"
            >
              <Image
                maxWidth="180px"
                src={ShopMoreEarnMore}
                objectFit="fit"
                loading="lazy"
              />
            </Center>
            <Center
              marginLeft="9em"
              width="120px"
              position="relative"
              zIndex="2"
              className={styles["shop-earn-more-box"]}
            >
              <Box>
                <Text className={styles["shop-earn-more-title"]}>Voucher</Text>
                <Center>
                  <VoucherIcon />
                </Center>
                <Text className={styles["shop-earn-more-subtitle"]}>
                  Discount up to 25%* only for member
                </Text>
              </Box>
            </Center>
          </Center>
          <Center className={styles["shop-earn-more-row"]}>
            <Center className={styles["shop-earn-more-box"]}>
              <Box className={styles["shop-earn-more-inner-box"]}>
                <Text className={styles["shop-earn-more-title"]}>
                  Surprise Gift
                </Text>
                <Center>
                  <SurpriseGiftIcon />
                </Center>
                <Text className={styles["shop-earn-more-subtitle"]}>
                  Surprise product or shopping voucher
                </Text>
              </Box>
            </Center>
            <Center className={styles["shop-earn-more-box"]}>
              <Box>
                <Text className={styles["shop-earn-more-title"]}>
                  Birthday Discount
                </Text>
                <Center>
                  <BirthdayDiscountIcon />
                </Center>
                <Text className={styles["shop-earn-more-subtitle"]}>
                  Special promotion on your birthday month
                </Text>
              </Box>
            </Center>
          </Center>
          <Center className={styles["shop-earn-more-row"]}>
            <Center className={styles["shop-earn-more-box"]}>
              <Box>
                <Text className={styles["shop-earn-more-title"]}>Freebies</Text>
                <Center>
                  <FreebiesIcon />
                </Center>
                <Text className={styles["shop-earn-more-subtitle"]}>
                  Get many exclusive freebies
                </Text>
              </Box>
            </Center>
            <Center className={styles["shop-earn-more-box"]}>
              <Box>
                <Text className={styles["shop-earn-more-title"]}>
                  Beauty Class
                </Text>
                <Center>
                  <BeautyClassIcon />
                </Center>
                <Text className={styles["shop-earn-more-subtitle"]}>
                  Exclusive beauty class for Emerald member
                </Text>
              </Box>
            </Center>
          </Center>
        </Box>
      </Box>
      <Box
        id="pointCalculation"
        className={styles["point-calculation-container"]}
      >
        <Box>
          <Box margin={"0 1.5em"}>
            <Text
              textAlign="center"
              className={styles["membership-rewards-text"]}
            >
              Uncover deals and benefit in Paragon Membership program with no
              expiry date point accumulation.
            </Text>
          </Box>
          <Box mt={"2em"}>
            <Box className={styles["point-calculation-table-header"]}>
              <Text className={styles["point-calculation-table-title"]}>
                Shopping Point Calculation
              </Text>
            </Box>
            <Center className={styles["point-calculation-table-content"]}>
              <Box mr={"0.5em"} width="25%">
                <Text className={styles["point-calculation-table-title2"]}>
                  Level
                </Text>
                <Text className={styles["point-calculation-table-title2"]}>
                  Earn Points
                </Text>
              </Box>
              <Box width="22.5%">
                <Text className={styles["point-calculation-table-title3"]}>
                  Crystal
                </Text>
                <Text className={styles["point-calculation-table-subtitle"]}>
                  Rp10k = 100pts
                </Text>
              </Box>
              <Box width="22.5%">
                <Text className={styles["point-calculation-table-title3"]}>
                  Diamond
                </Text>
                <Text className={styles["point-calculation-table-subtitle"]}>
                  Rp10k = 150pts
                </Text>
              </Box>
              <Box width="22.5%">
                <Text className={styles["point-calculation-table-title3"]}>
                  Emerald
                </Text>
                <Text className={styles["point-calculation-table-subtitle"]}>
                  Rp10k = 200pts
                </Text>
              </Box>
            </Center>
          </Box>
          <Box mt={"2em"}>
            <Box className={styles["point-activities-table-header"]}>
              <Text className={styles["point-calculation-table-title"]}>
                ACTIVITIES TO EARN MORE POINTS
              </Text>
            </Box>
            <Center className={styles["point-activities-table-content"]}>
              <Box width="94%" padding="10px 0">
                {activities?.map((element) => (
                  element.isInfoActive && (
                    <Flex gap={"0.5em"}>
                      <Box width="60%">
                        <Text
                          className={styles["point-activities-table-subtitle"]}
                        >
                          {element.title}
                        </Text>
                      </Box>
                      {element.isInfoActive && (
                        <Box
                          width="20%"
                        >
                          <button
                            size="sm"
                            className={styles["point-activities-table-icon"]}
                            onClick={() => {
                              onOpenInfoData();
                              setElementData(element);
                            }}
                          >
                            <InfoIcon />
                          </button>
                        </Box>
                      )}
                      <Box width="20%">
                        <Text
                          className={styles["point-activities-table-subtitle"]}
                        >
                          {new Intl.NumberFormat('en-US').format(element.points)} pts
                        </Text>
                      </Box>
                    </Flex>
                  )
                ))}
              </Box>
            </Center>
          </Box>
        </Box>
      </Box>
      <Box id="howItWorks" className={styles["how-itworks-container"]}>
        <Center mb="2em">
          <Image width="75%" src={HowItWorks} objectFit="fit" loading="lazy" />
        </Center>
        <Center className={styles["how-itworks-box"]}>
          <Box>
            <JoinForFreeIcon />
          </Box>
          <Box>
            <Text className={styles["how-itworks-title"]}>JOIN FOR FREE!</Text>
            <Text className={styles["how-itworks-subtitle"]}>
              Register via official Paragon brands’ website or offline store
            </Text>
          </Box>
        </Center>
        <Center className={styles["how-itworks-box"]}>
          <Box>
            <EarnPointsIcon />
          </Box>
          <Box>
            <Text className={styles["how-itworks-title"]}>EARN POINTS</Text>
            <Text className={styles["how-itworks-subtitle"]}>
              Get points for every purchase and do activities
            </Text>
          </Box>
        </Center>
        <Center className={styles["how-itworks-box"]}>
          <Box>
            <RedeemPointsIcon />
          </Box>
          <Box>
            <Text className={styles["how-itworks-title"]}>
              REDEEM YOUR POINTS
            </Text>
            <Text className={styles["how-itworks-subtitle"]}>
              Get discount by redeeming your points. Every 1 point = Rp1,-
            </Text>
          </Box>
        </Center>
        <Center className={styles["how-itworks-box"]}>
          <Box>
            <UpgradeIcon />
          </Box>
          <Box ml="0.75em">
            <Text className={styles["how-itworks-title"]}>UPGRADE</Text>
            <Text className={styles["how-itworks-subtitle"]}>
              Get more benefits by upgrading your membership level
            </Text>
          </Box>
        </Center>
      </Box>
      <Center padding="1em 0">
        <Button
          size="md"
          className={styles["join-now-color-scheme"]}
          colorScheme='#5c697f'
          onClick={() => {
            window.location.assign("./");
          }}
        >
          Join Now
        </Button>
      </Center>
      <Box id="logoShowcase" className={styles["logo-showcase-container"]}>
        <Grid templateColumns="repeat(3, 1fr)" gap={9}>
          <Center>
            <Image src={Wardah} loading="lazy" />
          </Center>
          <Center>
            <Image src={Emina} loading="lazy" />
          </Center>
          <Center>
            <Image src={Kahf} loading="lazy" />
          </Center>
          <Center>
            <Image src={Makeover} loading="lazy" />
          </Center>
          <Center>
            <Image src={Putri} loading="lazy" />
          </Center>
          <Center>
            <Image src={Biodef} loading="lazy" />
          </Center>
          <Center>
            <Image src={Labore} loading="lazy" />
          </Center>
          <Center>
            <Image src={Wonderly} loading="lazy" />
          </Center>
          <Center>
            <Image src={Tavi} loading="lazy" />
          </Center>
          <Center>
            <Image src={Instaperfect} loading="lazy" />
          </Center>
          <Center>
            <Image src={Crystallure} loading="lazy" />
          </Center>
          <Center>
            <Image src={EarthLoveLife} loading="lazy" />
          </Center>
        </Grid>
      </Box>
      <HomeInfoDrawer
        isOpen={isOpenInfoData}
        onClose={onCloseInfoData}
        elementData={elementData}
      />
    </div>
  );
}

export default About;
